import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routers from './Routers';
import 'lang/i18n'; 
import './styles/base.scss';

const App = () => {
  return (
        <BrowserRouter>
            <Routers/>
        </BrowserRouter>
  );
};

export default App;
