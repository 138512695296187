import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const req = require.context('.', true, /\.json$/);

const resources = req.keys().reduce((resources, filePath) => {
    const [, , lang, name] = filePath.split(/\/|\./);
    const contents = req(filePath);

    if (!resources[lang]) {
        resources[lang] = {};
    }

    resources[lang][name] = contents;

    return resources;
}, {});

i18n
    .use(LanguageDetector) // 브라우저 언어 탐지 및 설정 언어 기억
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        resources,
        fallbackLng: 'ko',
        debug: false, // true로 설정하면 콘솔에 디버깅 로그가 출력된다. 
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
    });

export default i18n;
