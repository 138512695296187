import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

// 기존 방식 (초기 로딩 시 모두 import)
// import { Main, About, Products, Education, Contact, Parob } from 'pages';

// lazy 함수롤 적용해 동적으로 필요할 때만 import
const Main = lazy(() => import("pages/Main/Main"));
const About = lazy(() => import("pages/About/About"));
const Products = lazy(() => import("pages/Products/Products"));
const Education = lazy(() => import("pages/Education/Education"));
const Contact = lazy(() => import("pages/Contact/Contact"));
const Parob = lazy(() => import("pages/Parob/Parob"));

const Routers = () => {
    return (
        <React.Fragment>
            <Suspense fallback={<div></div>}>
                <Switch>
                    <Route exact path="/" component={Main}/>
                    <Route exact path="/About" component={About}/>
                    <Route exact path="/Products" component={Products}/>
                    <Route exact path="/Education" component={Education}/>
                    <Route exact path="/Contact" component={Contact}/>
                    <Route exact path="/Parob" component={Parob}/>

                    {/* if NotFound redirect to Main page */}
                    <Route path="*">
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            </Suspense>
        </React.Fragment>
    );
};

export default withRouter(Routers);